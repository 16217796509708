<template>
  <div>
    <h1 class="display-1 font-weight-bold mx-1 mb-3">
      Greeting Cards
    </h1>
    <v-row>
      <v-col cols="12">
        <v-card fill-height>
          <v-img src="@/assets/Background-Greeting-Cards-2366x901.jpeg" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(card, index) in productFeatures" :key="index" :cols="card.cols">
        <v-card class="fill-height">
          <v-card-title>{{card.title}}</v-card-title>
          <v-card-text v-html="card.content"></v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import productFeatures from './GreetingCards.json'

export default {
  props: {
  },
  components: {
  },
  directives: {
  },
  data () {
    return {
      productFeatures
    }
  },
  async created () {
  },
  computed: {
  },
  watch: {},
  methods: {
  }
}
</script>
<style lang="scss" scoped>
  .v-card__title {
    font-size: 1rem
  }
</style>
